<template>
  <div>
    <button @click="openModalEventCreate" class="btn btn--outline">
      Create event
    </button>
    <DialogContainerTest theme="schedule-event-create">
      <!--  prettier-ignore -->
      {{ formData }}
      <ScheduleEventCreate :formData="formData" @submit="submitDataPrepare" />
    </DialogContainerTest>
  </div>
</template>

<script>
import ScheduleEventCreate from "@component/Schedule/ScheduleEventForm";
import DialogContainerTest from "@test/page/DialogContainerTest";
export default {
  components: {
    ScheduleEventCreate,
    DialogContainerTest,
  },
  data() {
    return {
      formData: {
        title: "Simple title",
        to: [1],
        description: "Simple text",
        datePeriod: {
          start: DateTime("2022-03-30T08:30:00Z"),
          end: DateTime("2022-03-30T11:30:00Z"),
        },
        repeat: 3,
        permissions: { modifyEvent: true, inviteOthers: true },
      },
    };
  },
  methods: {
    submitDataPrepare(submitData) {
      const { type, title, description, dateItem, datePeriod, community, to } =
        submitData;

      const submitDataPrepare = {
        type: type.toUpperCase(),
        title,
        description,
      };

      if (type === "meeting") {
        submitDataPrepare.startTime = datePeriod.start.utc().format();
        submitDataPrepare.endTime = datePeriod.end.utc().format();
        submitDataPrepare.community = {
          id: community.id,
        };
        // [ { id: "" }, ...];
        submitDataPrepare.participants = to.map((id) => ({ id }));
      }

      if (type === "task") {
        // [ { id: "" }, ...];
        submitDataPrepare.assignee = to.map((id) => ({ id }));
      }

      if (type !== "meeting") {
        submitDataPrepare.startTime = dateItem.utc().format();
      }

      console.log(submitDataPrepare);
    },
    openModalEventCreate() {
      //
      let modal = null;
      const closeModal = () => {
        modal && modal.close();
      };
      const props = {
        // data
        communityList: this.communityList,
        // events
        onSubmit: (submitObj) => {
          //communityAdd(submitObj.community, submitObj.contactList);
          console.log(submitObj);
          closeModal();
        },
        onTestEvent: (val) => {
          console.log('testEvent: ', val);
          closeModal();
        }
      };
      //
      modal = this.$dialog.open(ScheduleEventCreate, props, {
        group: "modal",
        theme: "event",
        close: true,
      });
      modal.then(() => {
        modal = null;
      });
    },
  },
};
</script>
